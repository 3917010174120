import { render, staticRenderFns } from "./PackageDefaultKidsDeleteDefaultDialog.vue?vue&type=template&id=c796e964&scoped=true"
import script from "./PackageDefaultKidsDeleteDefaultDialog.vue?vue&type=script&lang=js"
export * from "./PackageDefaultKidsDeleteDefaultDialog.vue?vue&type=script&lang=js"
import style0 from "./PackageDefaultKidsDeleteDefaultDialog.vue?vue&type=style&index=0&id=c796e964&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c796e964",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/SpMFxzSoQ/0/hero/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c796e964')) {
      api.createRecord('c796e964', component.options)
    } else {
      api.reload('c796e964', component.options)
    }
    module.hot.accept("./PackageDefaultKidsDeleteDefaultDialog.vue?vue&type=template&id=c796e964&scoped=true", function () {
      api.rerender('c796e964', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/finance/Package/PackageDefaultKidsDeleteDefaultDialog.vue"
export default component.exports